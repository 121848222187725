<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Flujo de Ventas</h4>
            <div class="small text-muted">Seguimiento de venta sobre un nuevo contacto interesado</div>
          </b-col>
          <b-col>            
            <b-icon icon="question-circle-fill" class="h3 pull-right mt-2 module-help-information" v-b-popover.hover="help"></b-icon>            
          </b-col>            
        </b-row>
      </b-card>

      <b-row>
        <b-col md="9">
          <b-row>
            <b-col md="4">               
              <Widget :configWidget="configWidget" reference="pie_sales_flow_leads_status" :hasAccess="configWidget.elements.salesFlowLeadsStatus" />                                          
            </b-col>
            <b-col md="4">               
              <Widget :configWidget="configWidget" reference="pie_sales_flow_leads_type" :hasAccess="configWidget.elements.salesFlowLeadsType" />                                          
            </b-col>            
            <b-col md="8"></b-col>
          </b-row>
        </b-col>  
        <b-col md="3">

          <b-list-group v-show="!isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openPotential()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Potenciales</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Seguimiento de venta sobre un nuevo potencial cliente.
              </p>
            </b-list-group-item>
          </b-list-group>                     
        
          <b-list-group v-show="!isSeller" class="mt-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCategories()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Categorías</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de las categorías de un potencial cliente.
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-show="!isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSource()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Origen</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración del origen de un potencial cliente.
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-show="!isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openLostMotive()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Motivo de Perdida</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de los motivos de pérdidas de un potencial cliente.
              </p>
            </b-list-group-item>
          </b-list-group>                             

          <b-list-group v-show="!isSeller && parameters.haveContract">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openContract()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Contrato</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Configuración del formato estandar del contrato.
              </p>
            </b-list-group-item>
          </b-list-group>    

          <b-list-group v-show="isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openPotentialSeller()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Potenciales</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Seguimiento de venta sobre un nuevo potencial cliente.
              </p>
            </b-list-group-item>
          </b-list-group> 

          <hr v-if="arr.PHPCustom.length">
          <b-list-group class="mt-2" v-if="arr.PHPCustom.length">
            <b-list-group-item v-for="item in arr.PHPCustom" :key="item.id" href="#" class="flex-column align-items-start" @click="openPHPCustom(item)">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.name}}</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                {{item.observations}}
              </p>
            </b-list-group-item>
          </b-list-group>
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import Param from '@/config/parameters'
  import ServicesPHP from '@/components/modules/users/php/staff/services'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.FLUJO_VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        parameters: {
          haveContract: Helper.hasParametersAccess(Param.P16),
        },        
        configWidget: {
          module_id: Modules.FLUJO_VENTAS,
          profile_id: Profiles.PERSONAL,
          elements: {             
            salesFlowLeadsStatus: true,            
            salesFlowLeadsType: true,            
          }
        },
        primaryColor: '',     
        arr: {
          PHPCustom: []
        },           
        help: {
          title:'¿Cómo funciona este módulo?',
          content: () => { 
            return `Un usuario con perfil <b>STAFF</b>, puede visualizar todos los potenciales 
                    clientes generados y continuar con el flujo completo.
                    <br><br>
                    Un usuario con perfil <b>STAFF</b> y configurado como <b>VENDEDOR</b> 
                    puede visualizar solo los potenciales clientes que tenga asignado
                    y continuar con el flujo completo.`
          },            
          html: true
        }          
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.salesFlowLeadsStatus = Helper.hasAccessWidget(this.configWidget, 'pie_sales_flow_leads_status')      
      this.configWidget.elements.salesFlowLeadsType = Helper.hasAccessWidget(this.configWidget, 'pie_sales_flow_leads_type')      
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
      this.getPHPCustom()
    }, 
    computed: {
      isSeller(){
        if(Helper.getSeller()) {
          return true
        } else {
          return false
        }
      }
    },        
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openPotential() {
        this.$router.push({ name: 'SalesFlowStaffCrudLeads' })
      },   
      openCategories(){
        this.$router.push({ name: 'SalesFlowStaffCrudCategories' })
      },
      openSource(){
        this.$router.push({ name: 'SalesFlowStaffCrudSource' })
      },     
      openLostMotive(){
        this.$router.push({ name: 'SalesFlowStaffCrudLostMotive' })
      },
      openPotentialSeller(){
        this.$router.push({ name: 'SalesFlowStaffCrudLeadsSellers' })
      },   
      openContract() {
        this.$router.push({ name: 'SalesFlowStaffCrudContract' })
      },

      getPHPCustom() {
        ServicesPHP.obtenerPHPByModule(Modules.FLUJO_VENTAS)
        .then((response) => {
          this.arr.PHPCustom = response.data       
        })
      },
      openPHPCustom(item) {
        this.$router.push({ name: 'ViewStaffViewPHPCustom', params: {id: item.id} })
      }      
    }    
   
  }
</script>

<style>

</style>
